.number-input{
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 12px;
    padding-bottom: 20px;
    color: var(--primary-color);
    border: 1px solid var(--medium-emphasis);
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.number-input:focus,
.number-input:hover {
    border-color: var(--primary-color);
    outline: none;
}

.number-input::placeholder {
    color : var(--medium-emphasis);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 32px;
    font-weight: normal;
    font-style: normal;
}