.addInfoPopup {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
.addInfoBox {
  position: relative;
  width: 592px;
  margin: 0 auto;
  background: #fff;
  max-height: 80vh;
  margin-top: 220px;
  border-radius: 24px;
  padding: 20px;
  border: 2px solid #828282;
  overflow: auto;
  box-sizing: border-box;
}

.infoBox {
  max-width: 544px;
  text-align: center;
}

.infoText {
  width: 490px;
  height: 92px;
  font-size: 32px;
  font-weight: 800;
  line-height: 48px;
  font-family: "poppins";
  margin-bottom: 0.5rem;
  display: inline;
}

.details {
  margin-top: 2rem;
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}

.text {
  width: 17rem;
  height: 48px;
  border-radius: 48px;
  border: 1px solid grey;
  text-align: center;
}
.placeHolder {
  width: 32px;
  height: 32px;
}

.infoButton {
  width: 100%;
  align-items: center;
  height: 3rem;
  border-radius: 24px;
  border: 1px solid black;
  color: #292929;
  background: var(--accent-color);
}
.infoButton:disabled {
  background: #aeaeae;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
