.authentication-bg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--navbar-height) - 40px);
  display: flex;
  /* margin-top : 64px; */
  flex-direction: column;
  justify-content: flex-start;
  background: url("../../assets/image/login_bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.authentication-card {
  padding: 0.75rem 2rem;
  margin: auto 15%;
  max-width: 412px;
}

.authentication-title {
  margin-bottom: 1rem;
  text-align: center;
}

.button-w100-mt21 {
  width: 100%;
  margin-top: 21px;
}

.authentication-p {
  margin-bottom: 10px;
  margin-top: 19px;
  text-align: center;
  color: var(--high-emphasis);
}

@media screen and (max-width: 576px) {
  .authentication-card {
    margin: auto 2%;
  }
}

.toast-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(41, 41, 41, 0.87) !important;
  color: white;
}

.toast-body-container {
  padding: 16px !important;
}
.inviteInfo {
  display: flex;
  gap: 40px;
}
.radio {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-size: 16.3px;
}

.radio:hover {
  color: #090909;
}

.heading {
  color: #090909;
  font-family: poppins;
  font-size: 16px;
  font-style: normal;
  margin-top: 1px;
}

.radioInput {
  padding-right: 20;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background-color: #ffc54d;
  display: none;
}

.radioRadio {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid gray;
  border-radius: 50%;
  margin-right: 4%;
  box-sizing: border-box;
  padding: 2px;
}

.radioRadio::after {
  content: "";
  width: 1.32rem;
  height: 1.32rem;
  display: block;
  border: 6px solid #ef9400;
  border-radius: 50%;
  transform: scale(0);
  transform: translate(-2px, -2px);
  transition: transform 0.15s;
}

.radioInput:checked + .radioRadio::after {
  transform: scale(1);
  transform: translate(-25%, -25%);
  color: rgb(9, 8, 8);
}
