.py5-px20{
    padding: 5px 20px;
}

.high-emphasis-bg {
    background-color: var(--high-emphasis);
}

.icon-medium {
    font-size: 22px;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.margin-right-8 {
    margin-right: 8px ;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-right-16 {
    margin-right: 16px;
}

.margin-vertical-5 {
    margin-top : 5px;
    margin-bottom : 5px;
}

.margin-right-26 {
    margin-right: 26px;
}

.py6-px16 {
    padding: 6px 16px;
}

.py4-px18 {
    padding: 4px 18px; 
}

.padding-top-22 {
    padding-top: 22px;
}


