/* rem and em do NOT depend on html font-size in media queries! Instead, 1rem = 1em = 16px */

/**************************/
/* BELOW 1344px (Smaller desktops) */
/**************************/

@media (max-width: 84em) {
  .main {
    max-width: 120rem;
    margin: 0 auto;
  }
  .rsvpbody {
    max-width: 90%;
    margin: 0 auto;
  }
  .p1 {
    font-size: 2rem;
    font-family: "Parisienne";
  }
}

/**************************/
/* BELOW 1200px (Landscape Tablets) */
/**************************/

@media (max-width: 75em) {
  html {
    /* 9px / 16px  56.25*/
  }

  .main {
    gap: 4.8rem;
    margin: 0 auto;
  }

  .rsvpbody {
    max-width: 80%;
    margin: 0 auto;
  }

  .userGroup {
    overflow-x: auto;
  }
}

/**************************/
/* BELOW 944px (Tablets) */
/**************************/

@media (max-width: 59em) {
  html {
    /* 8px / 16px = 0.5 = 50% */
    /* font-size: 40%; */
  }

  .main {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
    margin: 0 auto;
  }

  .rsvpbody {
    max-width: 80%;
    margin: 0 auto;
  }
  .invite {
    display: inline-flex;
    gap: 10%;
  }
  .arriving {
    display: inline-block;
    margin-top: 0rem;
  }
  .guestInfo {
    display: grid;
    grid-template-columns: (1fr, 1fr);
  }
  .parent {
    display: inline-flex;
    gap: 10%;
  }
  .kids {
    display: inline-flex;
    gap: 10%;
  }
}

/**************************/
/* BELOW 704px (Smaller tablets) */
/**************************/

@media (max-width: 44em) {
  .main {
    margin: 0 auto;
  }

  .rsvpbody {
    max-width: 100%;
    margin: 0 auto;
  }
  .body {
    grid-template-columns: 1fr;
  }
  .rsvp {
    font-size: 200%;
  }
  .p1 {
    font-size: 110%;
  }
  .invitee {
    font-size: 80%;
  }
  .userGroup {
    display: grid;
  }
  .subGroup {
    max-width: 50%;
    font-size: 70%;
  }
  .invite {
    display: inline-flex;
    gap: 10%;
  }
  .arriving {
    display: inline-block;
    margin-top: 0rem;
  }
  .guest {
    display: flex;
  }
  .checkboxes {
    display: grid;
    grid-template-columns: (1fr, 1fr);
  }
  .guestType {
    font-size: 85%;
  }
  .guestInfo {
    display: grid;
    grid-template-columns: (1fr, 1fr);
  }
  .parent {
    display: inline-flex;
    gap: 10%;
  }
  .kids {
    display: inline-flex;
    gap: 10%;
  }
  .confirm {
    font-size: 70%;
    width: auto;
  }
}

/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .rsvpbody {
    max-width: 80%;
    margin: 0 auto;
  }

  .main {
    padding: 0 3.2rem;
  }

  .rsvp {
    width: 150px;
    /* height: 50px; */
  }
  .p1 {
    font-size: 100%;
  }
  .invitee {
    font-size: 80%;
  }
  .userGroup {
    display: grid;
  }
  .subGroup {
    max-width: 50%;
    font-size: 70%;
  }
  .invite {
    display: inline-block;
    gap: 10%;
  }
  .arriving {
    display: inline-block;
    margin-top: 0rem;
  }
  .inviteInfo {
    font-size: 80%;
    gap: 20px;
  }
  .guest {
    display: flex;
    gap: 0.2em;
    /* display: block; */
  }

  .radioInput {
    font-size: 80%;
  }

  .guestType {
    font-size: 80%;
  }
  .guestInfo {
    display: grid;
  }
  .inputBox {
    width: 150px;
    height: 35px;
  }

  .parent {
    display: inline-block;
    gap: 10%;
  }
  .kids {
    display: inline-block;
    gap: 10%;
  }
  .confirm {
    font-size: 50%;
    width: auto;
  }
}

/* 
  - Font sizes (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
  
  - Spacing system (px)
  2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
  */
