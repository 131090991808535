.testimonial {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  /* font-size: 36px; */
  line-height: 50px;
}

.testimonialText {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  font-style: normal;
}
