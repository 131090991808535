.reset-password-card {
    min-width: 416px !important;
    max-width: 416px;
    padding : 1.5rem 2rem;
    box-shadow: none;
    border: 1px solid var(--low-emphasis);
}

.reset-password-card:hover {
    box-shadow: none;
}

@media screen and (max-width:420px) {
    .reset-password-card {
        min-width: 0 !important;
        width: 95%
    }
}

.content {
    position: absolute;
    top: calc(64px);
    bottom : 0;
    left : 0;
    right : 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

