.invite-carousel-wrapper .carousel-indicators li {
  background-color: white !important;
  opacity: 1;
  margin-left: 0;
  margin-right: 0;
  height: 6px;
  width: 4vw;
  border-top: 0px;
  border-bottom: 0px;
}

.invite-carousel-wrapper .carousel-indicators li:first-child {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.invite-carousel-wrapper .carousel-indicators li:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.invite-carousel-wrapper .carousel-indicators li.active {
  background-color: var(--grey-3-color) !important;
}

.invite-carousel-wrapper .carousel-inner > .carousel-item {
  height: calc(80vh);
}

.invite-carousel-wrapper .carousel-inner > .carousel-item > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 70vh;
  width: auto;
  max-width: 75%;
}

.invite-carousel-wrapper .carousel-control-next-icon {
  background-image: url("../../../assets/icon/carousel_right.svg") !important;
}

.invite-carousel-wrapper .carousel-control-prev-icon {
  background-image: url("../../../assets/icon/carousel_left.svg") !important;
}

.invite-carousel-wrapper .carousel-control-prev-icon,
.carousel-wrapper .carousel-control-next-icon {
  width: 40px !important;
  height: 40px !important;
}

.entityAnchor {
  height: 5rem;
  color: black;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 52px;
  font-family: "poppins";
  font-size: 18px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
}

.entityAnchor .back-arrow {
  background-image: url("../../../assets/icon/arrow_right.svg");
  width: 24px;
  height: 24px;
  top: 24px;
  left: 24px;
  padding: 6.5px, 3px, 6.5px, 3px;
}

.invitationTitle {
  white-space: pre-line;
}
