@media (max-width: 34rem) {
  .addInfoBox {
    position: relative;
    max-width: 290px;
    margin: 0 auto;
    background: #fff;
    max-height: 80vh;
    margin-top: 220px;
  }
  .infoBox {
    max-width: 250px;
    text-align: center;
  }
  .infoText {
    width: 200px;
    height: 92px;
    font-size: 25px;
    font-weight: 800;
    line-height: 48px;
    font-family: "poppins";
    margin-bottom: 0.5rem;
    display: inline;
  }
  .details {
    margin-top: 2rem;
    display: grid;
    grid-column: 1fr 1fr;
    /* justify-content: space-between; */
    gap: 20px;
  }
  .text {
    width: 15rem;
    height: 48px;
    border-radius: 48px;
    border: 1px solid grey;
    text-align: center;
  }
}
