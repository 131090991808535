.padding-7-percent {
  padding-left: 7% !important;
  padding-right: 7% !important;
}

.collection-card {
  min-width: 200px !important;
  border: none !important;
  margin-bottom: 10px;
}

h5 {
  color: var(--high-emphasis);
}

.card-heading {
  margin-top: 14px;
  margin-bottom: 5px;
}

.heading button {
  display: inline;
  float: right;
  padding: 8.5px 16px 8.5px 16px;
}

.collection-details {
  display: inline;
  color: var(--medium-emphasis);
}

.collection-details img {
  float: right !important;
}

.collection-details span {
  /* padding: 4px 0; */
  margin-right: 5px;
}

.userCenter {
  position: absolute;
  left: 50%;
  top: 12%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content h1 {
  margin-bottom: 23px;
  font-weight: normal;
}

.btn-icon-14 {
  font-size: 14px !important ;
}

.collection-img {
  aspect-ratio: 12/7;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 600px) {
  .heading {
    margin-left: 0px;
    margin-right: 0px;
  }
}
