.registerForm {
  width: 350px;
  margin: auto;
}

.logopart {
  background-color: black;
  width: 350px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 4rem;
  border-top: 10px;
  display: flex;
  flex-direction: start;
  font-style: inline;
  font-weight: bold;
  padding: 10px 12px;
}
