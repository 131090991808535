.logout-container {
  position: absolute !important;
  right: 5%;
  margin-top: 70px !important;
}

@media screen and (max-width: 500px) {
  .logout-container {
    margin-left: 4px !important;
    right: 10px;
  }
}

.profilePic {
  position: relative;
}

.hide {
  display: none;
}
