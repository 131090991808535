.folder-container {
  padding: 11px 12px;
  margin-bottom: 4px;
}

.sub-folder-container,
.add-folder-container {
  padding: 11px 12px;
  margin-left: 12px;
  margin-bottom: 4px;
}

.folder-container:not(.selected):hover {
  background-color: rgba(255, 197, 77, 0.5);
}

.folder-container.selected {
  background-color: var(--accent-color);
}

.sub-folder-container.selected {
  background-color: var(--secondary-color);
  border: 0.5px solid var(--low-emphasis);
  border-radius: 2px;
}

.sub-folder-container:not(.selected):hover {
  background-color: rgba(242, 242, 242, 0.5);
}

.folder-container.disabled:hover,
.sub-folder-container.disabled:hover {
  background-color: unset;
}

.folder-container.disabled,
.sub-folder-container.disabled,
.add-folder-container.disabled {
  pointer-events: none;
  opacity: 0.75;
}

.folder-item-icon {
  width: 16px;
  height: 16px;
}

.sub-folder-options {
  display: none;
}

.sub-folder-container.selected .sub-folder-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-style-type-none {
  list-style-type: none !important;
}

.edit-icon {
  max-width: 18px;
  max-height: 18px;
  margin-left: auto;
}

.edit-collection-thumbnail-icon {
  vertical-align: unset !important;
}

.collection-thumbnail-edit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
}
