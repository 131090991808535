/**************************/
/* BELOW 544px (Phones) */
/**************************/

@media (max-width: 34em) {
  .pbox {
    padding: 120px 18px;
  }
  .question {
    font-weight: 700rem;
    font-size: 18px;
    line-height: 30px;
  }
}
