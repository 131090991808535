.nav{
    background: #F2F2F2;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12); */
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: var(--primary-color) !important;
    border-bottom: 2px solid var(--high-emphasis);
    background-color: #F2F2F2 !important;
    border-color: transparent  transparent #000000 !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
    outline: none !important;
}

.nav-tabs .nav-link{
    border: 0px;
}

.bg-color{
    max-height: 43px;
    background-color: #F2F2F2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.nav a{
    color: var(--medium-emphasis);
}

.nav a:hover{
    color: var(--high-emphasis) !important;
}

.nav-justified > .nav-link, .nav-justified .nav-item{
    flex-basis: auto !important;
}

.margin-left-right{
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--navbar-height);
}

.info-container{
    background: #F2F2F2;
    max-height: 115px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 12%);
}

.info-content{
    margin-left: 15px;
    margin-right: 15px;
}

@media screen and (max-width:986px) {
    .info-container{
        max-height: 163px !important;
    }
}

@media screen and (max-width:453px) {
    .info-container{
        max-height: 213px !important;
    }
}