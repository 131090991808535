.option-outline-box {
    border: 1px solid var(--medium-emphasis);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    color: var(--medium-emphasis);
}

.option-outline-box.correct {
    border: 2px solid var(--accent-color);
    color: var(--primary-emphasis);
}

.btn-icon-small {
    height: 1.5em;
}