.popup{
    position: fixed;
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
}
  
.delete-box{
    position: relative;
    max-width: 416px;
    margin: 0 auto;
    background: #fff;
    max-height: 85vh;
    margin-top: calc(100vh - 70vh);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid rgba(41, 41, 41, 0.87);
    overflow: auto;
    box-sizing: border-box;
}

.close-icon{
    float: right;
    font-size: 18.5px;
    cursor: pointer;
}

.f-grow-1 {
    flex-grow: 1;
}

.f-gap-1{
    gap : 1rem;
}

