.image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.display-none {
    display: none;
}

.img-overlay{
    background: linear-gradient(180deg, rgba(41, 41, 41, 0) 0%, rgba(41, 41, 41, 0.9) 100%);
}

.icon-container{
    display: none;
    transform: translateY(100%);
    transition: 0.300ms ease-out;
    opacity: 0;
}

.masonry-image-container:hover .icon-container{
    display: flex;
    position: relative;
    padding-bottom: 8px;
    justify-content: flex-end;
    transform: translateY(0);
    opacity: 1;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    padding-top: 15px;
}

.masonry-image-container:hover .img-overlay{
    position: absolute;
    width: 100%;
    bottom: 0%;
    right: 0;
    display: flex;
    cursor: pointer;
}

.masonry-image-container{
    position: relative;
    /* margin-bottom: 10px; */
    width: 100%;
    height: 200px;
}

.favourite-icon{
    width: 20px;
}

.overlay-z-index{
    z-index: 1000;
}