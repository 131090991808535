.upload-card-border {
    border: 2px solid rgba(41, 41, 41, 0.6);
    border-radius: 8px;
}

.upload-area-border {
    border: 1px dashed rgba(41, 41, 41, 0.6);
    border-radius: 4px;
}

.upload-btn-padding {
    padding: 6px 12px; 
}

.padding-32 {
    padding: 32px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.upload-icon-size {
    max-width: 74px;
}

.margin-top-160 {
    margin-top: 160px;
}

.dropzone {
    text-align: center;
    padding: 24px 0px;
    background: #FFFFFF;
    border: 1px dashed rgba(41, 41, 41, 0.6);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}