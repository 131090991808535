.policy-outer-box {
  display: flex;
  max-width: 100%;
  background-color: #f2f2f2;
  height: auto;
  padding: 0.75rem 1rem;
  gap: 1rem;
}

.policy-item {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.textmessage {
  max-width: 17rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 20px;
  max-height: auto;
  padding: 4px 10px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  padding-right: 1rem;
}
.link {
  color: #006ce4;
}
.policy-text {
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  width: 15rem;
  height: 5.75rem;
}
.button-w49-mt21 {
  width: 49%;
  margin-top: 21px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}
