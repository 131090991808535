.otp-input {
    width: 100% !important;
    max-width: 2.5em;
    padding: 0.5em 0;
    border: 1px solid var(--medium-emphasis);
    border-radius: 4px;
}

.otp-input:focus {
    border: 1px solid var(--primary-color);
    outline: none;
}

.resend-otp.disabled {
    pointer-events: none;
    opacity: 0.5;
}