.image-container{
    justify-content: flex-start;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.upload-image-placeholder {
    background-image: url("../../../assets/placeholder/rectangle_45.jpg");
    width : 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.justify-self-center {
    justify-self: center;
}

.header{
    margin-top: 30px;
    display: flex;  
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
}

.mr16-mb-16{
    margin-right: 16px;
    margin-bottom: 16px;
}

.max-lines-two {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.progressBar {
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.progressBar > div {
    height: 15px;
    transition: width 1s;
}

.bg-success-color {
    background-color: var(--success-color);
}

.bg-error-color {
    background-color: var(--error-color);
}

/* .image {
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 200px;
} */

@media screen and (max-width:494px) {
    .image-container{
        justify-content: center ;
    }
}