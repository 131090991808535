.profile-header{
    margin-top: 28px;
    margin-bottom: 13px;
}

.padding-64{
    padding-top: 64px;
}

.image-button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
}

.preview-image-container {
    width: 200px;
    height: 120px;
}

.preview-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
}