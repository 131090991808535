.main {
  align-items: center;
  display: flex;
  margin: 0 auto;
  min-height: calc(100vh - 152px);
  background-color: white;
  /* margin-bottom: 20.5rem; */
}
.preference {
  display: flex;
  flex: 1 1 0%;
  overflow: auto;
}
.skipNowclass {
  margin-top: 4rem;
}

.transportPreference {
  display: flex;
  flex: 1 1 0%;
  overflow: auto;
}

/* .pbox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: auto;
  padding: 10rem 0px;
} */
.pbox {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.pbox2 {
  display: flex;
  justify-content: center;
}
.question {
  text-align: center;
  margin-top: 0.5rem;
  font-family: "Merriweather", sans-serif;
  font-style: normal;
  font-weight: 900rem;
  font-size: 22px;
  line-height: 36px;
}

.numOfQuestion {
  display: flex;
  z-index: 1;
  position: absolute;
  /* align-items: center; */
  bottom: 35px;
  left: 45%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}
.transportMode {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin: 5px;
}
.radioInput {
  padding-right: 20;
  cursor: pointer;
  width: 17px;
  height: 30px;
  border-radius: 70%;
  display: inline-block;
  position: relative;
  background-color: #ffc54d;
  display: none;
}
.radio {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-size: 16.3px;
}
.radioRadio {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid gray;
  border-radius: 50%;
  margin-right: 1%;
  box-sizing: border-box;
  padding: 2.9px;
}
.mode {
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500.28;
}
.stationStyle {
  margin-right: 20.9rem;
  font-size: 16.2px;
  font-weight: 500.15;
}
.custom-checkbox {
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  background-image: url("../../../assets/image/Selection.png");
}
.custom-checkbox:hover,
.custom-checkbox:checked {
  background-image: url("../../../assets/image/Selection1.png");
}
.custom-checkbox:disabled {
  background: #ccc;
  background-image: none;
  background-image: url("../../../assets/image/Selection.png");
}
.transportStationName {
  height: 7rem;
  font-weight: 500.3;
  font-size: 19spx;
}
.pick-up {
  display: inline-flex;
  gap: 11px;
}

.skip {
  margin-top: 3px;
  display: flex;
  justify-content: end;
  line-height: 2px;
}
