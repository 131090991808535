.heading-box {
  display: flex;
  align-items: center;
  background-color: #ffc54d;
  height: 48px;
  width: 850px;
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}

.heading-item {
  display: flex;
  justify-content: space-between;
  padding-left: 0.75rem;
  flex: 1 1;
  height: 100%;
}

.divider {
  height: 55%;
  width: 0.125rem;
  align-self: center;
  background-color: rgba(29, 29, 29, 0.6);
}

.ht-80pc {
  height: 80%;
}

.ht-90pc {
  height: 95%;
}

.edit-outer-box {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  max-width: 100%;
  background-color: #f2f2f2;
  align-items: center;
  height: 160px;
  padding: 0.75rem 1rem;
}

.edit-inner-box {
  display: flex;
  justify-content: space-between;
}

.edit-item {
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  width: 15rem;
  height: 5.75rem;
}
