.header-single-image-view {
    background-color: var(--secondary-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    min-height: 56px;
    padding: 0.25rem 0.75rem;
    display: flex;
    z-index: 9;
}

.sidebar-single-image-view {
    width: 25vw;
    background-color: var(--secondary-color);
}

.single-image-view-carousel-wrapper .carousel-inner > .carousel-item {
    height: 85vh;
}

.single-image-view-carousel-wrapper .carousel-inner > .carousel-item > img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 80vh;
    width: auto;
    max-width: 75%;
}

.single-image-view-carousel-wrapper {
    width: 100%;
}

.single-image-view-carousel-wrapper .carousel-control-next-icon {
    background-image: url("../../../assets/icon/carousel_right.svg") !important;
}

.single-image-view-carousel-wrapper .carousel-control-prev-icon {
    background-image: url("../../../assets/icon/carousel_left.svg") !important;
}

.single-image-view-carousel-wrapper .carousel-control-prev-icon, .single-image-view-carousel-wrapper .carousel-control-next-icon {
    width: 40px !important;
    height: 40px !important;
}

.img-description-textarea {
    background: var(--secondary-color);
    width: 100%;
    padding: 10px;
    border: 1px solid var(--low-emphasis);
    color: var(--high-emphasis);
    border-radius: 8px;
    height: 200px;
}

.img-description-textarea::placeholder {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.img-description-textarea:focus {
    outline: none;
    border: 1px solid var(--high-emphasis);
}

.description-cancel-btn {
    background: var(--low-emphasis);
    color : var(--secondary-color);
}

.like img {
    margin-right: 10px;
}

.editIconImage {
    margin-right: 5px;
    width: 16px;
}

.description-btn-container {
    display: flex;
    gap: 16px;
}

.flex-1 {
    flex:1;
}

.desc-btn-height {
    height: 40px;
}

.header-container {
    background-color: var(--secondary-color);
    /* position: absolute; */
    width: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 0px 10px;
    min-height: 56px;
}

.margin-left-5 {
    margin-left: 5px;
}

.mobile-view-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.z-index-1 {
    z-index: 1;
}

.z-index-1000 {
    z-index: 1000;
}

.mobile-view-folders-container {
  display: grid;
  grid-gap: 16px;  
  margin-bottom: 16px;
}

.icon-heading {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    vertical-align: middle;
    color: #141414;
    margin-bottom: 8px;
}

.icon-text {
    font-family: 'Lato',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(20, 20, 20, 0.6);
}

.icon-text-container {
    margin-left: 29px;
}

@media screen and (max-width:600px) {
    .single-image-view-carousel-wrapper {
        width: 100%;
    }

    .single-image-view-carousel-wrapper .carousel-inner > .carousel-item > img{
        max-width: 70%;
        height: auto;
    }

    .sidebar-single-image-view {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .description-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .description-cancel-btn {
        margin-bottom: 10px;
    }
}
