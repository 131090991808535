.entity-card {
  min-width: 200px !important;
  border: none !important;
  margin-bottom: 10px;
}

h5 {
  color: var(--high-emphasis);
}

.card-heading {
  margin-top: 14px;
  margin-bottom: 5px;
}

.entity-details {
  display: inline;
  color: var(--medium-emphasis);
}

.entity-details img {
  float: right !important;
}

.entity-details span {
  /* padding: 4px 0; */
  margin-right: 5px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-icon-14 {
  font-size: 14px !important ;
}
