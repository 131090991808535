.main {
  font-family: "poppins";
  padding-bottom: 2rem;
  background-repeat: repeat;
  background-color: "var(--secondary-color)";
}

.rsvpbody {
  background-image: url("../../../assets/image/RSVP.png");
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 50%;
  margin: 0 auto;
  width: 500px;
  height: 600px;
  top: 146px;
  left: 491px;
  border: 0.5px;
}

.rsvp {
  width: 183px;
  height: 114px;
  margin-left: 24px;
  border: 1px;
  font-family: "Qwigley";
  font-size: 72px;
  font-weight: 800;
  line-height: 114px;
  letter-spacing: 0em;
  margin-bottom: 0rem;
  /* text-align: center; */
}
.p1 {
  height: 40px;
  top: 122px;
  margin-left: 24px;
  border: 0.2, 5px;
  font-family: "Qwigley";
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  margin-bottom: 2rem;
}
.invitee {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  margin-left: 24px;
  margin-right: 24px;
  color: #5c3733;
  border-bottom: 2px solid #8a2b06;
}
.userGroup {
  display: flex;
  height: 55%;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 20px;
  padding: 0px 15px;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.subGroup {
  display: grid;
  gap: 20px;
  height: 60%;
}
.invite {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 1rem;
  /* margin-right: 2.8rem; */
}
.inviteInfo {
  display: flex;
  gap: 40px;
}
.radio {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}
.radio:hover {
  color: #ef9400;
}

.radioInput {
  padding-right: 20;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background-color: #ffc54d;
  display: none;
}
.radioRadio {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid gray;
  border-radius: 50%;
  margin-right: 4%;
  box-sizing: border-box;
  padding: 2px;
}

.radioRadio::after {
  content: "";
  width: 1.32rem;
  height: 1.32rem;
  display: block;
  border: 6px solid #ef9400;
  border-radius: 50%;
  transform: scale(0);
  /* transform: translate(-2px, -2px); */
  transition: transform 0.15s;
}
.radioInput:checked + .radioRadio::after {
  transform: scale(1);
  transform: translate(-25%, -25%);
  color: black;
}
.arriving {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 1.2rem;
  border-bottom: 2px solid gray;
}
.guest {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.guestType {
  font-family: "poppins";
  font-size: medium;
  font-weight: 400;
  line-height: 24px;
}
.checkboxes {
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  background-image: url("../../../assets/image/Selection.png");
}
.checkboxes:hover,
.checkboxes:checked {
  background-image: url("../../../assets/image/Selection1.png");
}
.checkboxes:disabled {
  background: #ccc;
  /* background-image: none; */
  background-image: url("../../../assets/image/Selection.png");
}

.self {
  display: flex;
  gap: 10px;
}
.parent {
  display: flex;
  gap: 130px;
}
.kids {
  display: flex;
  gap: 150px;
}
.guestInfo {
  margin-bottom: 1rem;
  display: flex;
  gap: 8px;
}
.guestCount {
  display: flex;
  gap: 22px;
}
.guestCountKids {
  display: flex;
  gap: 30px;
}
.inputBox {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid grey;
  color: black;
  font-weight: 500;
  padding: 10px 12px;
}
.submitButton {
  width: 100%;
  height: 3rem;
  margin-top: 2.2rem;
  font-family: "poppins";
  color: #ef9400;
  cursor: pointer;
}

.buttonAccent {
  background-color: #ef9400;
  color: #292929;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  align-items: center;
}

.buttonAccent:disabled {
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  align-items: center;
  background-color: #aeaeae;
  color: #292929;
}

.confirm {
  font-family: "poppins";
  font-weight: bolder;
  font-size: 1.1rem;
  text-align: center;
  width: 300px;
  padding: 10px 30px;
}
