figure.effect-sub-title-slide {
    position: relative;
    overflow: hidden;
    background-color: var(--medium-emphasis);
    cursor: pointer;
    margin: 0;
    z-index:0;
}

figure.effect-sub-title-slide .client-collection-image-container {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 234/131;
}


figure.effect-sub-title-slide figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-sub-title-slide figcaption {
    text-align: left;
}

figure.effect-sub-title-slide figcaption > * {
    z-index:10;
}

figure.effect-sub-title-slide h2.client-collection-title,
figure.effect-sub-title-slide p.client-collection-sub-title {
    position: absolute;
    text-align: start;
    right: 12px;
    left: 12px;
    padding: 0;
    margin: 0;
    color: var(--secondary-color);
}

figure.effect-sub-title-slide::after {
    content: '';
    background: linear-gradient(transparent, rgba(0,0,0,0.1), rgba(0,0,0,0.5));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

figure.effect-sub-title-slide p.client-collection-sub-title {
    bottom: 5px;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sub-title-slide h2.client-collection-title {
    bottom: 25px;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
}

figure.effect-sub-title-slide:hover h2.client-collection-title {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-sub-title-slide:hover p.client-collection-sub-title {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.collection-grid {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.regular-collection-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;
    grid-gap: 10px;
}

.collection-grid figure:first-child{
    grid-column: span 2;
    grid-row: span 2;
}

.collection-grid figure:first-child h2.client-collection-title,
.collection-grid figure:first-child p.client-collection-sub-title {
    position: absolute;
    text-align: start;
    right: 20px;
    left: 20px;
    padding: 0;
    margin: 0;
    color: var(--secondary-color);
}

.collection-grid figure:first-child h2.client-collection-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.collection-grid figure:first-child p.client-collection-sub-title span.regular {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.collection-grid figure:first-child p.client-collection-sub-title span.semi-bold {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.collection-grid figure.effect-sub-title-slide:first-child p.client-collection-sub-title {
    bottom: 10px;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.collection-grid figure.effect-sub-title-slide:first-child h2.client-collection-title {
    bottom: 40px;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,25px,0);
    transform: translate3d(0,25px,0);
}

.collection-grid figure.effect-sub-title-slide:first-child:hover h2.client-collection-title {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.collection-grid figure.effect-sub-title-slide:first-child:hover p.client-collection-sub-title {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

p.client-collection-sub-title span.regular {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
}

p.client-collection-sub-title span.semi-bold {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
}

h2.client-collection-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.star{
    width: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
}

@media screen and (max-width: 992px) {
    .collection-grid{
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    .regular-collection-grid {
        grid-template-columns: 1fr 1fr 1fr !important;
    }
}

@media screen and (max-width: 600px){
    .collection-grid{
        grid-template-columns: 1fr 1fr !important;
    }

    p.client-collection-sub-title span.regular {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 7px;
        line-height: 12px;
    }
    
    p.client-collection-sub-title span.semi-bold {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 7px;
        line-height: 12px;
    }
    
    h2.client-collection-title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
    }

    .collection-grid figure:first-child p.client-collection-sub-title span.regular{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
    }

    .collection-grid figure:first-child p.client-collection-sub-title span.semi-bold {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
    }

    .collection-grid figure:first-child h2.client-collection-title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .collection-grid figure.effect-sub-title-slide:first-child p.client-collection-sub-title {
        bottom: 5px;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
    }
    
    .collection-grid figure.effect-sub-title-slide:first-child h2.client-collection-title {
        bottom: 25px;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,25px,0);
        transform: translate3d(0, 20px,0);
    }

    figure.effect-sub-title-slide p.client-collection-sub-title {
        bottom: 5px;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
    }
    
    figure.effect-sub-title-slide h2.client-collection-title {
        bottom: 25px;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,20px,0);
        transform: translate3d(0,20px,0);
    }

    figure.effect-sub-title-slide p.client-collection-sub-title {
        bottom: 1px;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
    }
    
    figure.effect-sub-title-slide h2.client-collection-title {
        bottom: 15px;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,10px,0);
        transform: translate3d(0,10px,0);
    }

    .collection-grid figure:first-child h2.client-collection-title,
    .collection-grid figure:first-child p.client-collection-sub-title {
        position: absolute;
        text-align: start;
        right: 10px;
        left: 10px;
        padding: 0;
        margin: 0;
        color: var(--secondary-color);
    }

    figure.effect-sub-title-slide h2.client-collection-title,
    figure.effect-sub-title-slide p.client-collection-sub-title {
        position: absolute;
        text-align: start;
        right: 6px;
        left: 6px;
        padding: 0;
        margin: 0;
        color: var(--secondary-color);
    }

    .star{
        width: 16px;
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .regular-collection-grid{
        grid-template-columns: 1fr 1fr !important;
    }

}