.checkbox {
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1.5px solid rgba(8, 9, 9, 0.958);
  border-radius: 6px;
  background-color: hsl(85, 30%, 92%);
  margin-left: 15px;
  margin-right: 15px;

  &:checked {
    background-color: #fec20e;
  }

  &:checked:disabled {
    background-color: #979797;
  }

  &:focus {
    outline: none !important;
  }
}
