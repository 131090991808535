footer {
    background: var(--secondary-color);
    color: var(--primary-color);
    display: flex !important;
    justify-content: space-evenly;
    min-height: 40px;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 8px;
}

footer p {
    flex-basis: 25em;
    text-align: center;
    margin-bottom: 0px !important;
}

.footer-link{
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-basis:  65%;
    flex-wrap: wrap;
    align-items: center;
}

.footer-link a {
    text-decoration: none !important;
    flex: 1 1 10.75em;
    text-align: center;
    color: var(--primary-color) !important;
}
