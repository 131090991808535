.authentication-bg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--navbar-height) - 40px);
  display: flex;
  /* margin-top : 64px; */
  flex-direction: column;
  justify-content: flex-start;
  background: url("../../assets/image/login_bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.authentication-card {
  padding: 0.75rem 2rem;
  margin: auto 15%;
  max-width: 412px;
}

.authentication-title {
  margin-bottom: 1rem;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .authentication-card {
    margin: auto 2%;
  }
}
