.link-dark:hover {
  text-decoration: none !important;
  color: var(--high-emphasis) !important;
}

.link-light:hover {
  text-decoration: none !important;
  color: var(--secondary-color) !important;
}

.link-dark {
  color: var(--primary-color);
}

.link-light {
  color: var(--secondary-color);
}

.nav-prop {
  background-color: var(--primary-color) !important;
  padding: 0 7% !important;
  min-height: var(--navbar-height) !important;
}

.nav-button-props {
  padding: 5px 24px !important;
  margin: 15px 0px !important;
  max-width: 92px;
}

.nav-icon {
  background: var(--secondary-color);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  margin-left: 24px;
}

.profile-icon {
  overflow: hidden;
}

.nav-icon:hover {
  color: var(--high-emphasis) !important;
  text-decoration: none !important;
}

.nav-light {
  background: linear-gradient(to top, transparent, rgb(179, 176, 176));
  padding: 0 7% !important;
  min-height: var(--navbar-height) !important;
}

.back-arrow-img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.margin-left-24 {
  margin-left: 24px;
}

.nav-back-arrow {
  width: 32px;
  margin-bottom: 8px;
  margin-right: 5px;
}

@media screen and (max-width: 991px) {
  .nav-icon {
    margin-left: 0px;
    /* margin-top: 5px; */
    margin-bottom: 5px;
  }

  .margin-left-24 {
    margin-left: 10px;
  }
}

.event-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .event-title {
    display: none;
  }
}

/* myphotosOnly */

.toggle-container {
  padding: 11px 12px;
  margin-bottom: 4px;
}

.toggle-container.selected {
  background-color: var(--primary-color);
}
