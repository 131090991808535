.popup {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  max-width: 848px;
  margin: 0 auto;
  background: #fff;
  max-height: 80vh;
  margin-top: calc(100vh - 85vh);
  border-radius: 8px;
  padding: 20px;
  border: 2px solid #828282;
  overflow: auto;
  box-sizing: border-box;
}

.close-icon {
  float: right;
  font-size: 18.5px;
}

@media screen and (max-width: 900px) {
  .box {
    margin-left: 2%;
    margin-right: 2%;
  }
}
