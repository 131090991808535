.top-box {
  display: flex;
  /* align-items: center; */
  background-color: #ffc54d;
  height: 5.125rem;
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
}

.policy-header {
  font-size: 1.5rem;
  font-weight: 800;
}

.policy-content {
  font-size: 1.2rem;
  font-weight: 600;
}

.inner-text {
  display: flex;
  justify-content: space-between;
  padding-left: 0.75rem;
  height: 100%;
}

.flex-1-1 {
  flex: 1 1;
}

.flex-2-1 {
  flex: 2 1;
}

.detail-box {
  margin-bottom: 0.75rem;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  background-color: #f2f2f2;
  height: auto;
  padding: 0.75rem 1rem;
}

.policy-divider {
  height: 3.625rem;
  width: 0.125rem;
  align-self: center;
  background-color: rgba(29, 29, 29, 0.3);
}

.ht-8 {
  height: 10rem;
}

.link {
  color: #006ce4;
}

@media screen and (max-width: 34rem) {
  .textmessages {
    max-width: 0.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.3rem;
    max-height: auto;
    padding: 2px 2px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    padding-right: 5rem;
  }
}
