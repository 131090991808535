.pop-up {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container-outline {
  position: relative;
  max-width: 416px;
  margin: 0 auto;
  background: #fff;
  max-height: 85vh;
  margin-top: calc(100vh - 70vh);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid rgba(41, 41, 41, 0.87);
  overflow: auto;
  box-sizing: border-box;
}

.container-simple {
  position: relative;
  max-width: 416px;
  margin: 0 auto;
  background: #fff;
  max-height: 85vh;
  margin-top: calc(100vh - 70vh);
  border-radius: 8px;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .container-outline {
    max-width: 343px;
  }
  .container-simple {
    max-width: 343px;
  }
}
