.question-outline-btn.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.question-option-btn:last-child {
    margin-bottom: 0 !important;
}

.pointer-events-none {
    pointer-events: none;
}